const pauseHowls = () =>{
  const howls = window.Howler._howls;
    howls && howls.forEach(howl=>{
      howl.pause();
  });
}

const getParams = function (url=window.location.href) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

export {
  pauseHowls,getParams
}
